define("shared/components/save-cancel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dMLKuuYc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,13],[[35,12]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[16,0,[31,[\"btn-large btn \",[34,10]]]],[24,4,\"button\"],[4,[38,5],[[32,0],\"cancel\"],null],[12],[2,\"\\n    \"],[1,[30,[36,7],[[35,11]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[18,1,null],[2,\"\\n\"],[6,[37,4],[[35,14]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[16,0,[31,[\"btn-large btn btn-disabled \",[34,8]]]],[24,4,\"button\"],[4,[38,5],[[32,0],\"doNothing\"],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"icon icon-spinner icon-spin\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,7],[[35,9]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"button\"],[16,\"disabled\",[34,0]],[16,0,[31,[\"btn-large btn \",[30,[36,4],[[35,3],[35,2],[35,1]],null]]]],[24,4,\"submit\"],[4,[38,5],[[32,0],\"save\"],null],[12],[2,\"\\n    \"],[1,[30,[36,7],[[35,6]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"saveDisabled\",\"saveColor\",\"savedColor\",\"saved\",\"if\",\"action\",\"btnLabel\",\"t\",\"savingColor\",\"savingLabel\",\"cancelColor\",\"cancelLabel\",\"cancelDisabled\",\"unless\",\"saving\"]}",
    "moduleName": "shared/components/save-cancel/template.hbs"
  });

  _exports.default = _default;
});