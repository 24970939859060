define("shared/components/authorized-endpoint/component", ["exports", "shared/components/authorized-endpoint/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    layout: _template.default,
    cluster: null,
    clusterTemplateRevision: null,
    enableLocalClusterAuthEndpoint: false,
    clusterTemplateCreate: false,
    applyClusterTemplate: null,

    init() {
      this._super(...arguments);

      this.initClusterAuthEndpoint();
    },

    enableClusterAuthEndpointChanged: Ember.observer('enableLocalClusterAuthEndpoint', function () {
      const {
        cluster,
        enableLocalClusterAuthEndpoint
      } = this;

      if (enableLocalClusterAuthEndpoint) {
        if (cluster.localClusterAuthEndpoint) {
          Ember.set(cluster, 'localClusterAuthEndpoint.enabled', true);
        } else {
          this.createLocalClusterAuthEndpoint();
        }
      } else {
        Ember.set(cluster, 'localClusterAuthEndpoint.enabled', false);
      }
    }),
    enableLocalClusterAuthEndpointAvailable: Ember.computed('enableLocalClusterAuthEndpoint', 'clusterTemplateRevision.questions.[]', function () {
      let {
        clusterTemplateRevision
      } = this;
      let {
        questions = []
      } = clusterTemplateRevision ? clusterTemplateRevision : {};
      let match = questions.find(question => {
        if ((question.variable || '').includes('localClusterAuthEndpoint')) {
          return question;
        }

        return false;
      });
      return match && match.length > 0;
    }),

    initClusterAuthEndpoint() {
      const {
        cluster
      } = this;

      if (cluster && cluster.localClusterAuthEndpoint && (typeof cluster.localClusterAuthEndpoint.enabled === 'string' && cluster.localClusterAuthEndpoint.enabled === 'true' || typeof cluster.localClusterAuthEndpoint.enabled === 'boolean' && cluster.localClusterAuthEndpoint.enabled)) {
        Ember.set(this, 'enableLocalClusterAuthEndpoint', true);
      } else {
        Ember.set(this, 'enableLocalClusterAuthEndpoint', false);
      }
    },

    createLocalClusterAuthEndpoint() {
      const {
        globalStore
      } = this;
      const lcae = globalStore.createRecord({
        type: 'localClusterAuthEndpoint',
        enabled: true,
        fqdn: '',
        caCerts: ''
      });
      Ember.set(this, 'cluster.localClusterAuthEndpoint', lcae);
    }

  });

  _exports.default = _default;
});