define("shared/components/drain-node/component", ["exports", "shared/components/drain-node/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MAX_TIMEOUT = 10800;
  const HONOR_GRACE_PERIOD = -1;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    layout: _template.default,
    editable: true,
    selection: null,
    proxyGracePeriod: null,
    usePodGracePeriod: false,
    proxyTimeout: null,
    unlimitedTimeout: false,
    view: Ember.computed.not('editable'),

    init() {
      this._super(...arguments);

      const force = Ember.get(this, 'selection.force');
      const deleteLocalData = Ember.get(this, 'selection.deleteLocalData');
      Ember.set(this, 'selection.force', typeof force === 'string' ? force === 'true' : !!force);
      Ember.set(this, 'selection.deleteLocalData', typeof deleteLocalData === 'string' ? deleteLocalData === 'true' : !!deleteLocalData);
      Ember.set(this, 'usePodGracePeriod', this.selection.gracePeriod === HONOR_GRACE_PERIOD);
      Ember.set(this, 'proxyGracePeriod', this.selection.gracePeriod === HONOR_GRACE_PERIOD ? 30 : this.selection.gracePeriod);
      Ember.set(this, 'unlimitedTimeout', this.selection.timeout === MAX_TIMEOUT);
      Ember.set(this, 'proxyTimeout', this.selection.timeout === MAX_TIMEOUT ? 60 : this.selection.timeout);
    },

    updateAggressive: Ember.observer('aggressive', function () {
      Ember.set(this, 'selection.force', Ember.get(this, 'aggressive'));
      Ember.set(this, 'selection.deleteLocalData', Ember.get(this, 'aggressive'));
    }),
    updateGracePeriod: Ember.observer('usePodGracePeriod', 'proxyGracePeriod', function () {
      const newGracePeriod = Ember.get(this, 'usePodGracePeriod') ? HONOR_GRACE_PERIOD : Ember.get(this, 'proxyGracePeriod');
      Ember.set(this, 'selection.gracePeriod', newGracePeriod);
    }),
    updateTimeout: Ember.observer('unlimitedTimeout', 'proxyTimeout', function () {
      const newTimeout = Ember.get(this, 'unlimitedTimeout') ? MAX_TIMEOUT : Ember.get(this, 'proxyTimeout');
      Ember.set(this, 'selection.timeout', newTimeout);
    }),
    gracePeriodForPods: Ember.computed('selection.{gracePeriod,usePodGracePeriod}', 'usePodGracePeriod', function () {
      return this.usePodGracePeriod ? this.intl.t('drainNode.gracePeriod.default') : this.intl.t('drainNode.gracePeriod.view.custom', {
        seconds: this.selection.gracePeriod
      });
    }),
    timeout: Ember.computed('selection.{timeout,unlimitedTimeout}', 'unlimitedTimeout', function () {
      return this.unlimitedTimeout ? this.intl.t('drainNode.timeout.default') : this.intl.t('drainNode.timeout.view.custom', {
        seconds: this.selection.timeout
      });
    })
  });

  _exports.default = _default;
});