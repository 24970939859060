define("shared/components/cru-node-pools/component", ["exports", "shared/components/cru-node-pools/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const headers = [{
    name: 'hostnamePrefix',
    sort: ['hostnamePrefix', 'id'],
    translationKey: 'clusterNew.rke.nodes.hostnamePrefix',
    scope: 'embedded'
  }, {
    name: 'count',
    sort: ['quantity', 'displayName.id'],
    translationKey: 'clusterNew.rke.nodes.count',
    width: 100
  }, {
    name: 'nodeTemplate',
    sort: ['nodeTemplate.displayName', 'nodeTemplate.id'],
    translationKey: 'clusterNew.rke.nodes.template'
  }, {
    name: 'deleteNotReadyAfterSecs',
    sort: ['deleteNotReadyAfterSecs'],
    translationKey: 'clusterNew.rke.nodes.deleteNotReadyAfterSecs',
    width: 155
  }, {
    name: 'aggressivedrain',
    sort: false,
    scope: 'embedded',
    translationKey: 'clusterNew.rke.nodes.drainBeforeDelete',
    classNames: ['pl-5'],
    width: 150
  }, {
    name: 'etcd',
    sort: false,
    translationKey: 'clusterNew.rke.role.header.etcd',
    classNames: ['text-center'],
    width: 100
  }, {
    name: 'controlplane',
    sort: false,
    translationKey: 'clusterNew.rke.role.header.controlplane',
    classNames: ['text-center'],
    width: 100
  }, {
    name: 'worker',
    sort: false,
    translationKey: 'clusterNew.rke.role.header.worker',
    scope: 'embedded',
    classNames: ['text-center'],
    width: 100
  }, {
    name: 'advanced',
    sort: false,
    scope: 'embedded',
    translationKey: 'clusterNew.rke.nodes.taints',
    classNames: ['text-center'],
    width: 100
  }, {
    name: 'remove',
    sort: false,
    classNames: ['text-center'],
    width: 35
  }];
  const HARVESTER = 'harvester';

  var _default = Ember.Component.extend({
    globalStore: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    layout: _template.default,
    cluster: null,
    nodeTemplates: null,
    driver: null,
    // docker-machine driver
    model: null,
    originalPools: null,
    nodePools: null,
    errors: null,
    taintCapabilites: Ember.computed.alias('scope.currentCluster.capabilities.taintSupport'),

    init() {
      this._super(...arguments);

      const originalPools = (Ember.get(this, 'cluster.nodePools') || []).slice();
      Ember.set(this, 'originalPools', originalPools);
      Ember.set(this, 'nodePools', originalPools.slice().map(p => p.clone()));

      if (Ember.get(this, 'mode') === 'new' && Ember.get(originalPools, 'length') === 0) {
        Ember.get(this, 'nodePools').pushObject(Ember.get(this, 'globalStore').createRecord({
          type: 'nodePool',
          quantity: 1,
          worker: true
        }));
      }

      if (this.registerHook) {
        this.registerHook(this.savePools.bind(this), 'savePools');
      }

      this.setDefaultNodeTemplate();
    },

    didReceiveAttrs() {
      this.validate();
    },

    actions: {
      addPool() {
        let nodePools = Ember.get(this, 'nodePools');
        let templateId = null;
        const lastNode = nodePools[nodePools.length - 1];

        if (lastNode) {
          templateId = Ember.get(lastNode, 'nodeTemplateId');
        }

        nodePools.pushObject(Ember.get(this, 'globalStore').createRecord({
          type: 'nodePool',
          nodeTemplateId: templateId,
          worker: true
        }));
      },

      removePool(pool) {
        Ember.get(this, 'nodePools').removeObject(pool);
      },

      addNodeTemplate(node) {
        Ember.get(this, 'modalService').toggleModal('modal-edit-node-template', {
          nodeTemplate: null,
          driver: Ember.get(this, 'driver'),

          onAdd(nodeTemplate) {
            Ember.set(node, 'nodeTemplateId', Ember.get(nodeTemplate, 'id'));
          }

        });
      },

      configAdvancedOptions(pool) {
        Ember.get(this, 'modalService').toggleModal('modal-edit-node-pool', pool);
      }

    },
    setDefaultNodeTemplate: Ember.observer('driver', function () {
      const templates = Ember.get(this, 'filteredNodeTemplates') || [];

      if (templates.length === 1) {
        (Ember.get(this, 'nodePools') || []).forEach(pool => {
          if (!Ember.get(pool, 'nodeTemplateId')) {
            Ember.set(pool, 'nodeTemplateId', Ember.get(templates, 'firstObject.id'));
          }
        });

        if (Ember.get(this, 'driver') === HARVESTER) {
          this.nodeTemplateIdChanged();
        }
      }
    }),
    driverChanged: Ember.observer('driver', function () {
      const driver = Ember.get(this, 'driver');
      Ember.get(this, 'nodePools').forEach(pool => {
        const tpl = Ember.get(pool, 'nodeTemplate');

        if (tpl && Ember.get(tpl, 'driver') !== driver) {
          Ember.set(pool, 'nodeTemplateId', null);
        }
      });
    }),
    nodeTemplateIdChanged: Ember.observer('nodePools.@each.{nodeTemplateId}', 'driver', function () {
      if (Ember.get(this, 'driver') === HARVESTER) {
        Ember.set(this, 'model.harvesterNodeTemplateId', Ember.get(this, 'nodePools.firstObject.nodeTemplateId'));
      }
    }),
    validate: Ember.observer('etcdOk', 'controlPlaneOk', 'workerOk', 'nodePools.@each.{quantity,hostnamePrefix,nodeTemplateId}', 'nodePools.[]', 'driver', function () {
      const intl = Ember.get(this, 'intl');
      const driver = Ember.get(this, 'driver');
      const errors = [];

      if (Ember.get(this, 'mode') === 'new' && !Ember.get(this, 'etcdOk')) {
        errors.push(intl.t('clusterNew.rke.errors.etcd'));
      }

      if (!Ember.get(this, 'controlPlaneOk')) {
        errors.push(intl.t('clusterNew.rke.errors.controlPlane'));
      }

      if (!Ember.get(this, 'workerOk')) {
        errors.push(intl.t('clusterNew.rke.errors.worker'));
      }

      if (driver === 'digitalocean') {
        const nodePools = Ember.get(this, 'nodePools');
        let isError = false;
        nodePools.map(p => {
          const {
            hostnamePrefix = ''
          } = p;

          if (hostnamePrefix.includes('_')) {
            isError = true;
          }
        });

        if (isError) {
          errors.push(intl.t('nodeDriver.digitalocean.errors.hostnamePrefix'));
        }
      }

      if (this.uniqueHostNameError) {
        errors.push(intl.t('clusterNew.nodePools.errors.hostnamePrefix'));
      }

      Ember.get(this, 'nodePools').forEach(pool => {
        // ClusterId is required but not known yet
        if (!Ember.get(pool, 'clusterId')) {
          Ember.set(pool, 'clusterId', '__later__');
        }

        errors.pushObjects(pool.validationErrors());
      });

      if (this.setNodePoolErrors) {
        this.setNodePoolErrors(errors);
      }
    }),
    showIamWarning: Ember.computed('driver', 'nodePools.@each.nodeTemplateId', 'cluster.rancherKubernetesEngineConfig.cloudProvider.name', function () {
      const cloudProvider = Ember.get(this, 'cluster.rancherKubernetesEngineConfig.cloudProvider.name');
      const driver = Ember.get(this, 'driver');

      if (driver === 'amazonec2' && cloudProvider === 'aws') {
        const found = (Ember.get(this, 'nodePools') || []).some(pool => {
          const nodeTemplate = Ember.get(pool, 'nodeTemplate');
          return nodeTemplate && !Ember.get(nodeTemplate, 'amazonec2Config.iamInstanceProfile');
        });
        return found ? true : false;
      }

      return false;
    }),
    filteredNodeTemplates: Ember.computed('driver', 'nodePools.@each.nodeTemplateId', 'nodePools.firstObject.nodeTemplate.harvesterConfig.vmNamespace', 'nodeTemplates.@each.{driver,state}', function () {
      const driver = Ember.get(this, 'driver');
      let templates = Ember.get(this, 'nodeTemplates').filterBy('state', 'active').filterBy('driver', driver);
      (Ember.get(this, 'nodePools') || []).forEach(pool => {
        const templateId = Ember.get(pool, 'nodeTemplateId');

        if (templateId && !templates.findBy('id', templateId)) {
          templates.push(Ember.get(this, 'globalStore').createRecord({
            type: 'nodetemplate',
            id: templateId,
            name: `(${templateId})`
          }));
        }
      });

      if (driver === HARVESTER && Ember.get(this, 'nodePools.length') > 1) {
        templates.map(t => {
          const namespace = Ember.get(this, 'nodePools.firstObject.nodeTemplate.harvesterConfig.vmNamespace');

          if (namespace) {
            if (Ember.get(t, 'harvesterConfig.vmNamespace') !== namespace) {
              Ember.set(t, 'disabled', true);
            } else {
              Ember.set(t, 'disabled', false);
            }
          } else {
            Ember.set(t, 'disabled', false);
          }

          return t;
        });
      } else {
        templates.map(t => Ember.set(t, 'disabled', null));
      }

      return templates;
    }),
    uniqueHostNameError: Ember.computed('nodePools.@each.hostnamePrefix', function () {
      // Host name prefixes must be unique
      const nodePools = Ember.get(this, 'nodePools');
      const names = {};
      let isError = false;
      nodePools.map(p => {
        const {
          hostnamePrefix = ''
        } = p;

        if (names[hostnamePrefix]) {
          isError = true;
        } else {
          names[hostnamePrefix] = true;
        }
      });
      return isError;
    }),
    etcdOk: Ember.computed('nodePools.@each.{quantity,etcd}', function () {
      let count = this._nodeCountFor('etcd');

      return count === 1 || count === 3 || count === 5;
    }),
    etcdCount: Ember.computed('nodePools.@each.{quantity,etcd}', function () {
      return this._nodeCountFor('etcd');
    }),
    controlPlaneOk: Ember.computed('nodePools.@each.{quantity,controlPlane}', function () {
      let count = this._nodeCountFor('controlPlane');

      return count >= 1;
    }),
    workerOk: Ember.computed('nodePools.@each.{quantity,worker}', function () {
      let count = this._nodeCountFor('worker');

      return count >= 1;
    }),
    headers: Ember.computed('driver', 'mode', 'taintCapabilites', function () {
      const {
        taintCapabilites,
        mode
      } = this;
      const nodeTemplate = headers.find(h => h.name === 'nodeTemplate') || {};

      if (this.driver === HARVESTER) {
        nodeTemplate.tooltip = this.intl.t('nodeDriver.harvester.nodeTemplate.tooltip');
      } else {
        delete nodeTemplate.tooltip;
      }

      if (taintCapabilites || mode === 'new') {
        return headers;
      } else {
        return headers.filter(h => h.name !== 'advanced');
      }
    }),

    savePools() {
      if (this.isDestroyed || this.isDestroying || Ember.get(this, 'driver') === 'custom') {
        return;
      }

      const nodePools = Ember.get(this, 'nodePools');
      const original = Ember.get(this, 'originalPools');
      const remove = [];
      original.forEach(pool => {
        if (!nodePools.some(p => p.id === pool.id)) {
          // Remove
          remove.push(pool);
        }
      });
      const clusterId = Ember.get(this, 'cluster.id');
      nodePools.forEach(pool => {
        const {
          controlPlane,
          etcd,
          worker
        } = pool;
        Ember.set(pool, 'clusterId', clusterId);

        if (!controlPlane && !etcd && !worker) {
          Ember.set(pool, 'worker', true);
        }
      });
      return Ember.RSVP.all(nodePools.map(x => x.save())).then(() => {
        return Ember.RSVP.all(remove.map(x => x.delete())).then(() => {
          return Ember.get(this, 'cluster');
        });
      });
    },

    _nodeCountFor(role) {
      let count = 0;
      (Ember.get(this, 'nodePools') || []).filterBy(role, true).forEach(pool => {
        let more = Ember.get(pool, 'quantity');

        if (more) {
          more = parseInt(more, 10);
        }

        count += more;
      });
      return count;
    }

  });

  _exports.default = _default;
});