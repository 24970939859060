define("shared/cis-helpers/service", ["exports", "shared/utils/util", "shared/utils/stateful-promise"], function (_exports, _util, _statefulPromise) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    globalStore: Ember.inject.service(),
    intl: Ember.inject.service(),
    securityScanConfig: Ember.inject.service(),

    createProfileKey(profile, benchmark) {
      return profile && benchmark ? `${benchmark.toUpperCase()} ${profile} ` : '';
    },

    clusterScanConfigToProfile(scanConfig) {
      return this.createProfileKey(scanConfig.cisScanConfig.profile, scanConfig.cisScanConfig.overrideBenchmarkVersion);
    },

    profileToClusterScanConfig(profileRaw) {
      const [benchmark, profile] = profileRaw.toLowerCase().split(' ');
      return {
        cisScanConfig: {
          failuresOnly: false,
          skip: null,
          overrideBenchmarkVersion: benchmark,
          profile
        }
      };
    },

    /**
     * Converts an id that looks like 1.1.9 into 000010000100009. This
     * allows us to appropriately compare the ids as if they are versions
     * instead of just doing a naive string comparison.
     * @param {*} id
     */
    createSortableId(id) {
      const columnWidth = 5;
      const splitId = id.trim().split('.');
      return splitId.map(column => {
        const suffix = column.match(/[a-z]$/i) ? '' : 'a';
        const columnWithSuffix = column + suffix;
        const columnPaddingWidth = Math.max(columnWidth - columnWithSuffix.length, 0);
        return '0'.repeat(columnPaddingWidth) + columnWithSuffix;
      }).join('');
    },

    defaultClusterScanConfig: Ember.computed('defaultCisScanProfileOption', function () {
      return this.profileToClusterScanConfig(this.defaultCisScanProfileOption);
    }),
    // Fetching sicScanProfiles, cisConfigs, etc
    // will now return 404 because the
    // V1 CIS scan feature was removed.
    cisScanConfigProfiles: [],
    cisConfigs: [],
    benchmarkMapping: Ember.computed('cisConfigs.value', () => {
      return [];
    }),
    benchmarkMappingValues: Ember.computed('benchmarkMapping', function () {
      return Object.values(Ember.get(this, 'benchmarkMapping'));
    }),
    cisScanBenchmarks: Ember.computed.uniq('benchmarkMappingValues'),
    cisScanProfiles: Ember.computed('cisScanConfigProfiles', 'cisScanBenchmarks', function () {
      const profiles = Ember.get(this, 'cisScanConfigProfiles');
      const benchmarks = Ember.get(this, 'cisScanBenchmarks');
      const asArray = profiles.flatMap(profile => {
        return benchmarks.map(benchmark => ({
          [this.createProfileKey(profile, benchmark)]: {
            benchmark,
            profile
          }
        }));
      });
      return asArray.length > 0 ? Object.assign.apply({}, asArray) : {};
    }),

    getDefaultCisScanProfileOption(kubernetesVersion) {
      const mapping = Ember.get(this, 'benchmarkMapping');
      const version = kubernetesVersion.split('.').slice(0, 2).join('.');
      const defaultBenchmark = mapping[version] ? mapping[version] : mapping['default'];
      const defaultProfile = Ember.get(this, 'cisScanConfigProfiles')[0];
      return this.createProfileKey(defaultProfile, defaultBenchmark);
    },

    cisScanProfileOptions: Ember.computed('cisScanProfiles', function () {
      return Object.keys(Ember.get(this, 'cisScanProfiles')).map(key => ({
        label: (0, _util.toTitle)(key),
        value: key
      }));
    }),
    defaultCisScanProfileOption: Ember.computed('cisScanProfileOptions', function () {
      return Ember.get(this, 'cisScanProfileOptions')[0].value;
    }),
    benchmarkVersions: Ember.computed(function () {
      return _statefulPromise.default.wrap(this.globalStore.findAll('cisBenchmarkVersion'), []);
    }),
    benchmarkLookup: Ember.computed('benchmarkVersions.value', 'securityScanConfig.parsedSecurityScanConfig', 'securityScanConfig.parsedSecurityScanConfig.skip', function () {
      const getUserSkip = benchmark => {
        try {
          const userSkipLookup = Ember.get(this, 'securityScanConfig.parsedSecurityScanConfig.skip');
          const userSkip = userSkipLookup[benchmark];
          const skips = Array.isArray(userSkip) && userSkip.every(s => typeof s === 'string') ? userSkip : [];
          return skips;
        } catch (ex) {
          return [];
        }
      };

      return Ember.get(this, 'benchmarkVersions.value').filter(bv => bv.info.notApplicableChecks && bv.info.skippedChecks).reduce((agg, bv) => ({ ...agg,
        [bv.name]: {
          notApplicableChecks: Object.entries(bv.info.notApplicableChecks).map(e => ({
            sortableId: this.createSortableId(e[0]),
            id: e[0],
            why: e[1]
          })).sortBy('sortableId'),
          skippedChecks: Object.entries(bv.info.skippedChecks).map(e => ({
            sortableId: this.createSortableId(e[0]),
            id: e[0],
            why: e[1]
          })).sortBy('sortableId'),
          userSkippedChecks: getUserSkip(bv.name)
        }
      }), {});
    })
  });

  _exports.default = _default;
});