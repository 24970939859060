define("nodes/components/boot-order-select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "e2krO9Fn",
    "block": "{\"symbols\":[],\"statements\":[[8,\"searchable-select\",[],[[\"@class\",\"@content\",\"@value\",\"@placeholder\"],[\"form-control\",[34,0],[34,1,[\"bootOrder\"]],[30,[36,2],[\"nodeDriver.harvester.storageClass.placeholder\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"bootOrderContent\",\"disk\",\"t\"]}",
    "moduleName": "nodes/components/boot-order-select/template.hbs"
  });

  _exports.default = _default;
});