define("shared/helpers/has-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasProperty = hasProperty;
  _exports.default = void 0;

  function hasProperty(params) {
    return Object.prototype.hasOwnProperty.call(params[0], params[1]);
  }

  var _default = Ember.Helper.helper(hasProperty);

  _exports.default = _default;
});