define("nodes/components/boot-order-select/component", ["exports", "nodes/components/boot-order-select/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    session: Ember.inject.service(),
    layout: _template.default,
    model: null,
    idx: '',
    disk: {},
    disks: [],
    bootOrderContent: Ember.computed('disk.bootOrder', 'disks.@each.bootOrder', 'idx', function () {
      let bootOrderContent = [];
      const bootOrder = Ember.get(this, 'disk').bootOrder;
      const bootOrders = Ember.get(this, 'disks').map(disk => {
        return disk.bootOrder;
      });
      const disks = Ember.get(this, 'disks') || [];
      const idx = Ember.get(this, 'idx');

      for (let i = 0; i < disks.length + 1; i++) {
        if (!bootOrders.includes(i) || i === 0 || i === bootOrder) {
          if (!(idx === 0 && i === 0)) {
            bootOrderContent.push({
              label: i === 0 ? 'N/A' : `${i}`,
              value: i
            });
          }
        }
      }

      return bootOrderContent;
    })
  });

  _exports.default = _default;
});